import React, { useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { setup, createInstance } from "@loomhq/record-sdk";
import { isSupported } from "@loomhq/record-sdk/is-supported";
import { oembed } from "@loomhq/loom-embed";
import { IconButton, Button, Box, Container, Grid, Paper, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, styled, CircularProgress, Alert, Snackbar, TableContainer, TableCell, TableBody, Table, TableRow, TableHead } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw";
import { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import SyncIcon from '@mui/icons-material/Sync';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StopIcon from '@mui/icons-material/Stop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import onboardPlay from './onboard-play.svg';
import onboardStar from './onboard-star.svg';
import onboardMic from './onboard-mic.svg';
import Avatar from '@mui/material/Avatar';
import { RetellWebClient } from "retell-client-js-sdk";
import ZoomVideo from '@zoom/videosdk';
import ReactGA from "react-ga4";
import Logo from './wayfaster-icon-bg.svg';
import Soundbar from './soundbar.svg';
import Listening from './Listening.svg';
import WebcamPreviewModal from "./components/WebcamPreviewModal.tsx";
import posthog from 'posthog-js'
import VideoFeed from './components/VideoFeed.tsx';
import TranscriptMessage from './components/TranscriptMessage.tsx';
import { Tabs, Tab } from '@mui/material';
import { getLocalStorageUserId } from './util/util.js'
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useMaybeRoomContext,
  useTrackTranscription,
  useVoiceAssistant,
  useRemoteParticipants,
  useParticipantAttributes,
  useConnectionState,
  useParticipantTracks,
  useLocalParticipant,
  useTracks
} from '@livekit/components-react';

import { ParticipantKind, Track } from 'livekit-client';

import '@livekit/components-styles';

import WayfasterLogoW from './wayfaster-logo-w.svg';
import WayfasterLogo from './wayfaster-logo.svg';
import { buildRetellEnvKey, getRetellAgentPhoto } from './envMapping.js'
import ReactMarkdown from 'react-markdown';

const _ = require('lodash');
const googleAnalyticsTrackingId = "G-KEJZXKN80Q"
const livekitUserIdentity = "wayfaster-web-ui"

ReactGA.initialize([
  {
    trackingId: googleAnalyticsTrackingId,
  },
]);
ReactGA.send({ hitType: "pageview", page: "/", title: "hit" });

// TODO: move to process.env.DEEPGRAM_API_KEY
var callId;
var socket;
var client = ZoomVideo.createClient();
var stream;
var sessionLength;
var introDone = false;

const retellWebClient = new RetellWebClient();

var proxyEndpoint = process.env.REACT_APP_PROXY_ENDPOINT || 'https://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var wsEndpoint = 'wss://lit-dusk-21105-d4d3c182eb10.herokuapp.com'
var env = String(process.env.REACT_APP_ENV)

const PUBLIC_APP_ID = process.env.REACT_APP_LOOM_PUBLIC_APP_ID
const BUTTON_ID = "loom-record-sdk-button";

// RETELL ENDPOINTS
var retellApiEndpoint = 'https://retell-main-proxy-69440b772c45.herokuapp.com'
var retellWsEndpoint = 'wss://retell-main-proxy-69440b772c45.herokuapp.com'

if (env === 'staging') {
  proxyEndpoint = 'https://staging-proxy-6f8d86796ff2.herokuapp.com'
  wsEndpoint = 'wss://staging-proxy-6f8d86796ff2.herokuapp.com'
  retellApiEndpoint = 'https://staging-core-voice-api-2ac3e4de69f0.herokuapp.com'
} else if (env === 'development') {
  proxyEndpoint = 'http://localhost:5555'
  wsEndpoint = 'ws://localhost:5555'
  retellApiEndpoint = 'http://localhost:8080'
  retellWsEndpoint = 'ws://localhost:8080'
}

var stopInvoked = false;
const emptyInterviewObject = {
  unique_session_id: null,
  state: 'none',
  messages: [],
  context: [],
  mute: false,
  metadata: {
    interview_config: {
      title: '',
      questions: [{
        question: '',
        type: '',
        metadata: {}
      }]
    }
  },
  scorecard: [{ signal: 'test', feedback: 'test feedback', score: 4, asked_to_candidate: false, scale: 4 }],
  scoring_pending: false,
  parrot_text_index: 0
}

const OnboardLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: "#F4E132",
  },
}))


// TODO: SWITCH BACK, only for staging testing
var interviewDuration = 600;
var globalInterviewType = 'system_design_event_ticketing'
var interviewerName = 'Steve'
const urlParams = new URLSearchParams(window.location.search);
const interviewTypeQueryParam = urlParams.get('interview_type');
if (interviewTypeQueryParam) {
  globalInterviewType = interviewTypeQueryParam
}
var allSysDesignParam = urlParams.get('all_sys_design');
if (!allSysDesignParam) {
  allSysDesignParam = 'false'
}

// will tell us if we're in a dedicated session or not (a session is a pre-defined interview basically tied to an account)
const sessionId = urlParams.get('session');
if (sessionId) {
  globalInterviewType = ''
}

if (globalInterviewType === 'eve') {
  interviewerName = 'Isabelle'
}

const onboardCopy = [
  {
    prompt: <>
      <p className="font-inter" style={{ fontSize: '14px', lineHeight: '20px', fontWeight: '400', color: '#8A8F8F' }}>
        Wayfaster AI is a voice interview that allows you to be interviewed in a bias-free way only on your skills, faster than a human.
      </p>
    </>,
    icon: onboardStar,
    flag: "start",
    heading: <><p className="font-inter" style={{ margin: '0', color: '#151F20', fontSize: '20px', fontWeight: '600' }}>Get Started</p></>,
    action: 'Got it'
  },
  {
    prompt: <>
      <p className="font-inter" style={{ fontSize: '14px', lineHeight: '20px', fontWeight: '400', color: '#8A8F8F' }}>
          Before we start, we'd like you to meet one of our interviewers, Steve. He will help guide you through the interview process!
      </p>
    </>,
    icon: onboardPlay,
    note: <div className="d-flex mx-auto" style={{ width: 'fit-content', background: '#FFFBEB', color: '#9A3412', fontSize: '12px', padding: '10px', fontWeight: 600 }}>
      <InfoOutlinedIcon style={{ fontSize: '16px', marginRight: '8px', color: '#9A3412' }} /> No pressure, just a friendly chat to get to know each other!
    </div>,
    flag: "mic",
    extra: 'No pressure, just a friendly chat to get to know each other!',
    heading: <><p className="font-inter" style={{ color: '#151F20', fontSize: '20px', fontWeight: '600' }}>
      Say hello to Steve!
    </p></>,
    practiceChat: true,
    action: "Start a friendly chat with Steve",
    actionFn: function () {
      // create intro call 
      console.log('register intro call')
      return fetch(`${retellApiEndpoint}/register-intro-call`, {
        method: 'POST',
        body: JSON.stringify({
          'session_id': sessionId
        })
      })
        .then((registerRes) => {
          console.log('res', registerRes)
          registerRes.json()
            .then((jsonRegisterRes) => {
              console.log(jsonRegisterRes)
              retellWebClient.startCall({
                accessToken: jsonRegisterRes.access_token,
                enableUpdate: false
              })
                .then(() => {
                  console.log('conversation has started')
                })
                .catch((err) => {
                  console.error(err)
                })
            })
            .catch((jsonErr) => {
              console.error(jsonErr)
            })
        })
        .catch((err) => {
          console.error('err', err)
        })
    }
  },
  {
    interviewScreen: true,
  },
  {
    introVideo: true,
  },
  {
    heading: <><p className="font-inter" style={{ color: '#151F20', fontSize: '20px', fontWeight: '600' }}>
      Start the Interview
    </p></>,
    prompt: <p className="font-inter" style={{ fontSize: '14px', lineHeight: '20px', fontWeight: '400', color: '#8A8F8F' }}>
      Now it's time for a real conversation. Our interviewer will talk to you about your background and experience. You will be asked to turn on your camera and microphone.
    </p>,
    flag: "end",
    action: "Let's go",
  }]

const App = () => {
  const navigate = useNavigate()

  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [onboardStep, setOnboardStep] = React.useState(0);
  const [onboardProgress, setOnboardProgress] = React.useState(25);
  const [onboardIcon, setOnboardIcon] = React.useState(onboardCopy[0].icon);
  const [onboardPrompt, setOnboardPrompt] = React.useState(onboardCopy[0].prompt);
  const [onboardNote, setOnboardNote] = React.useState(onboardCopy[0].note);
  const [onboardAction, setOnboardAction] = useState(onboardCopy[0].action);
  const [onboardHeading, setOnboardHeading] = useState(onboardCopy[0].heading)
  const [waitlistIsOpen, setWaitlistIsOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [interview, setInterview] = useState(emptyInterviewObject);
  const [openHelp, setOpenHelp] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [loadingDiagramFeedback, setLoadingDiagramFeedback] = useState(false);
  const [micEnabled, setMicEnabled] = useState(false);
  const [waitingEnable, setWaitingEnable] = useState(false);
  const [waitingDemo, setWaitingDemo] = useState(false);
  const [visualState, setVisualState] = useState('');
  const [firstMobileScreenDone, setFirstMobileScreenDone] = useState(false);
  const [timerElapsed, setTimerElapsed] = React.useState(0);
  const [showGracefulExitMsg, setShowGracefulExitMsg] = useState(false)
  const [showStopInterviewConfirmation, setShowStopInterviewConfirmation] = useState(false)
  const [showInterviewEndedMidway, setShowInterviewEndedMidway] = useState(false)
  const [closeOnboarding, setCloseOnboarding] = useState(false)
  const [sessionLoading, setSessionLoading] = useState(sessionId ? true : false)
  const [signalIntroDone, setSignalIntroDone] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)

  // one or the other
  const [scorecard, setScorecard] = useState([])
  const [scorecards, setScorecards] = useState([])

  // Waitlist
  const [subscriptionSuccessSnackOpen, setSubscriptionSuccessSnackOpen] = React.useState(false);
  const [subscriptionFailSnackOpen, setSubscriptionFailSnackOpen] = React.useState(false);
  const [session, setSession] = useState(null)
  const [sessionAttempted, setSessionAttempted] = useState(false)
  const [submission, setSubmission] = useState('')
  const [feedback, setFeedback] = useState('')
  const [feedbackPending, setFeedbackPending] = useState(false)
  const [fullSession, setFullSession] = useState(null)

  // Tabs
  const [currentRightSidebarTab, setCurrentRightSidebarTab] = useState(0)
  const [overview, setOverview] = useState(``);

  const handleRightSidebarTabChange = (event, newValue) => {
    setCurrentRightSidebarTab(newValue)
  }

  const AIName = useMemo(() => {
    return session && session.voice ? session.voice.split('-')[1] : 'Steve'
  }, [session])

  const candidateName = useMemo(() => {
    return session && session.candidate_metadata && session.candidate_metadata.first_name + ' ' + session.candidate_metadata.last_name ? session.candidate_metadata.first_name + ' ' + session.candidate_metadata.last_name : 'Candidate' 
  }, [session])  
  // const room = useMaybeRoomContext();

  // useEffect(() => {
  //   if (!room) {
  //     return;
  //   }
  
  //   const updateTranscriptions = (segments, participant, publication) => {
  //     setTranscript((prev) => {
  //       console.log('prev', prev)
  //       const newTranscriptions = { ...prev };
  //       for (const segment of segments) {
  //         newTranscriptions[segment.id] = segment;
  //       }
  //       console.log('newTranscriptions', newTranscriptions)
  //       return newTranscriptions;
  //     });
  //   };
  
  //   room.on(RoomEvent.TranscriptionReceived, updateTranscriptions);
  //   return () => {
  //     room.off(RoomEvent.TranscriptionReceived, updateTranscriptions);
  //   };
  // }, [room]);  

  // loom
  const [videoHTML, setVideoHTML] = useState("");
  useEffect(() => {
    async function setupLoom() {
      console.log('setupLoom')
      const { supported, error } = await isSupported();

      if (!session) {
        return
      }

      if (session && session.videoRecorder !== 'loom') {
        console.log('not loom', session.videoRecorder)
        return
      }

      if (!supported) {
        console.warn(`Error setting up Loom: ${error}`);
        return;
      }

      const button = document.getElementById(BUTTON_ID);
      if (!button) {
        return;
      }

      const { configureButton } = await createInstance({
        mode: 'standard',
        publicAppId: PUBLIC_APP_ID,
        config: {
          allowedRecordingTypes: ['cam'],
          defaultRecordingType: 'cam'
        }
      })

      const sdkButton = configureButton({ element: button });

      sdkButton.on("recording-start", async () => {
        console.log('setting mic enabled')
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          .then(function (stream) {
            setMicEnabled(true);
            handleStartInterview();
          })
          .catch(function (err) {
            console.log('Microphone permission denied');
          });
      });

      sdkButton.on("recording-complete", async (video) => {
        const { html } = await oembed(video.sharedUrl, { width: 400 });
        // setVideoHTML(html);
        console.log('finished')
        // store this url to the interview
        console.log('session', session)
        axios.post(proxyEndpoint + '/interview_sessions/' + session._id, {
          interview_session: {
            ...fullSession,
            videoRecordingUrl: video.sharedUrl
          }
        })
          .then(response => {
            console.log('interview updated with url', response)
          })
          .catch(err => {
            console.error(err);
          });
      })
    }

    if (fullSession && interview) {
      console.log('session', session)
      setupLoom();
    }
  }, [micEnabled, setMicEnabled, session, fullSession, onboardStep]);
  const [introTitle, setIntroTitle] = useState('')
  const [useIntroTitle, setUseIntroTitle] = useState(false)
  const [introMsgCount, setIntroMsgCount] = useState(0)
  const [transcript, setTranscript] = useState([])
  const [livekitToken, setLivekitToken] = useState(null)

  // Replace the dragging state variables with a simple toggle
  const [transcriptExpanded, setTranscriptExpanded] = useState(false);
  
  // Replace the touch handlers with a simple toggle function
  const toggleTranscript = () => {
    setTranscriptExpanded(!transcriptExpanded);
  };


  // Initialize the SDK
  useEffect(() => {
    // Keep a ref to latest transcript value that we can access in any callback
    const transcriptRef = { current: transcript };

  }, [session, setUseIntroTitle, setInterviewStarted, setTranscript, transcript, useIntroTitle]);

  // signal intro done
  useEffect(() => {
    if(signalIntroDone) {
      setTranscript([])
      setUseIntroTitle(false)
      setInterviewStarted(false)
      setTimer(session.length)
      advanceOnboardStep()
      setVisualState('')
      introDone = true
    }
  }, [signalIntroDone])

  if (sessionId && session === null) {
    axios.post(`${proxyEndpoint}/session_details`, {
      sessionId: sessionId
    })
      .then((response) => {
        // if response.data.attempted is true, show a message to the candidate that they have already attempted this interview
        if (response.data.attempted) {
          setSessionAttempted(true)
          setSessionLoading(false)
          return
        } else {
          setSession(response.data)
          setOverview(response.data.overview)
        }

        setSessionLoading(false)
        var length = response.data.length
        if (length !== undefined) {
          if (typeof length === 'string') {
            length = parseInt(length);
          }

          setTimer(length * 60)
          sessionLength = length * 60
          console.log(sessionLength)
          axios.post(`${proxyEndpoint}/session`, {
            id: response.data._id,
            sessionId: sessionId
          })
            .then((response) => {
              console.log(response.data)
              setFullSession(response.data)
            })
            .catch((err) => {
              console.log('err on getting full session details')
            })
        }
        const hashParams = new URLSearchParams(window.location.hash.substring(1))
        const distinct_id = hashParams.get('distinct_id')
        const user_id = getLocalStorageUserId();
        // posthog identify with candidate information
        // use session id as unique identifier
        let candidate_metadata = response.data.candidate_metadata;
        if (distinct_id) {
          posthog.identify(distinct_id, {
            "email": candidate_metadata.email,
            "given_name": candidate_metadata.first_name,
            "family_name": candidate_metadata.last_name,
            "call_type": candidate_metadata.call_type,
            "phone": candidate_metadata.phone,

            "user_type": "candidate",

            "unique_session_id": sessionId,
            "interview_config_id": response.data.interview_config_id,
            "interview_user_id": response.data.user_id,
            "interview_team_id": response.data.team_id,
          })
          if (candidate_metadata.email) {
            posthog.alias(distinct_id, candidate_metadata.email)
          }

          if (window.location.href.startsWith('https://sessions.wayfaster.com')) {
            posthog.capture('sessions__valid_session_found', {
              'current_url': window.location.href,
              'user_id': user_id,
              'unique_session_id': sessionId,
            })
            console.log('posthog event captured -- valid_session_found', user_id)
          }

          // should redirect given that this is a Direct session (go back to candidates.wayfaster.com)
          setShouldRedirect(true)
        }        
      })
      .catch((error) => {
        console.error('Error fetching session details:', error);
        setSessionLoading(false)
      });
  }

  const handleCloseSuccessSnack = () => {
    setSubscriptionSuccessSnackOpen(false);
  }

  const handleCloseFailSnack = () => {
    setSubscriptionFailSnackOpen(false);
  }

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleCloseSubscriptionFailSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSubscriptionFailSnackOpen(false);
  };

  const handleCloseSubscriptionSuccessSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSubscriptionSuccessSnackOpen(false);
  };

  // Tabs
  const [tabValue, setTabValue] = React.useState(0);

  // Mobile home screen mode
  const [startMobileDemo, setStartMobileDemo] = React.useState(false);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };


  // Material UI responsive layout settings
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const excalidrawStyle = {
    minHeight: '60vh',
    width: '100%',
    background: '#050505', // Change the background colo
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // UI
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    fontFamily: 'figtree, sans-serif', // Set the font family to "figtree"
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginRight: '20px'
  }));

  // Timer
  const [timer, setTimer] = React.useState(interviewDuration);

  useEffect(() => {
    let interval = null;
    if (interviewStarted) {
      interval = setTimeout(() => {
        if (timer > 0 && !showGracefulExitMsg) {
          setTimer(timer => timer - 1)
          setTimerElapsed(timerElapsed => timerElapsed + 1)
        }
      }, 1000);
    }

    return () => {
      if (interval !== null && timer <= 0) {
        clearTimeout(interval);
      }
    };
  }, [interviewStarted, timer, useIntroTitle]);
  // Help button
  const handleHelpOpen = () => {
    setOpenHelp(true);
  }

  const handleHelpClose = () => {
    setOpenHelp(false);
  };

  const handleStartMobileDemo = () => {
    setStartMobileDemo(true);
  }

  if (excalidrawAPI !== null && !updated) {
    fetch('prefill.excalidraw.json')
      .then(response => response.json())
      .then(data => {
        excalidrawAPI.updateScene({ elements: data.elements, appState: data.appState });
        excalidrawAPI.scrollToContent(data.elements, {
          fitToContent: true,
          animate: false,
        });
        setUpdated(true);
        const appState = excalidrawAPI.getAppState();
        const elements = excalidrawAPI.getSceneElements();
        if (!elements || !elements.length) {
          return
        }

        exportToBlob({
          elements,
          appState: {
            ...appState,
            exportWithDarkMode: false,
          },
          files: excalidrawAPI.getFiles(),
          maxWidthOrHeight: 1000
        })
          .catch((error) => {
            console.error('Error:', error);
          });
      })
  }

  const initRetellWebsocketConnection = (registerRes) => {
    console.log('initRetellWebsocketConnection', registerRes, registerRes.call_id, registerRes.access_token)
    var socket = new WebSocket(retellWsEndpoint + '/register-candidate-client/' + registerRes.call_id)
    socket.onmessage = function (event) {
      const jsonEvent = JSON.parse(event.data)
      if (jsonEvent.type === 'update') {
        const jsonData = jsonEvent.data
        setInterview(jsonData)
      } else if (jsonEvent.type === 'done') {
        handleStopRetellInterview(false)
      } else if (jsonEvent.type === 'register') {
        console.log(registerRes)  
        retellWebClient.startCall({
          accessToken: registerRes.access_token,
          sampleRate: registerRes.sample_rate,
          enableUpdate: false
        })
          .then(() => {
            console.log('conversation has started')
          })
          .catch((err) => {
            console.error(err)
            retellWebClient.startCall({
              accessToken: registerRes.access_token,
              sampleRate: registerRes.sample_rate,
              enableUpdate: false
            })
              .then(() => {
                console.log('conversation has started (2nd try)')
              })
              .catch((err) => {
                console.error(err)
                retellWebClient.startCall({
                  accessToken: registerRes.access_token,
                  sampleRate: registerRes.sample_rate,
                  enableUpdate: false
                })
                  .then(() => {
                    console.log('conversation has started (3rd try)')
                  })
                  .catch((err) => {
                    console.error(err)
                    retellWebClient.startCall({
                      accessToken: registerRes.access_token,
                      sampleRate: registerRes.sample_rate,
                      enableUpdate: false
                    })
                      .then(() => {
                        console.log('conversation has started (4th try)')
                      })
                      .catch((err) => {
                        console.error(err)
                      })
                  })
              })
          })
      }
    }

    return socket
  }

  const initWebsocketConnection = (callId, interviewTypeParam, sessionId) => {
    let clientType = 'desktop'
    if (isMobile) {
      clientType = 'mobile'
    }

    console.log('initWebsocketConnection')
    console.log(globalInterviewType);
    console.log('sessionId', sessionId)

    const socket = io(wsEndpoint, {
      withCredentials: true,
      transports: ["websocket"]
    });
    socket.on('connect', () => {
      console.log('Websocket connected');
    });

    socket.on('disconnect', () => {
      console.log('Websocket disconnected');
    });

    socket.emit('register', {
      callId: callId,
      clientType: clientType,
      interviewType: interviewTypeParam,
      sessionId: sessionId,
    });
    socket.on('update', (interview) => {
      console.log('update', interview)
      var tmpInterview = interview
      if (typeof tmpInterview === 'string') {
        tmpInterview = JSON.parse(interview);
      }
      console.log(tmpInterview.scorecard)
      if (tmpInterview.metadata.callId === callId) {
        setInterview(tmpInterview);
      }
    });
    socket.on('done', (data) => {
      console.log('done', data)
      if (data.callId === callId) {
        console.log('done true', interview)
        handleStopInterview()
      }
    })
    socket.on('parrot', (res) => {
      console.log('par', res.callId)
      if (res.callId === callId) {
        console.log('parroting')
      }
    })

    return socket;
  }

  const handleStartInterview = (interviewTypeParam) => {
    console.log('handleStartInterview')
    setInterviewStarted(true);
    setCloseOnboarding(true);
    var interviewType = 'system_design_event_ticketing'
    var sessionId = null
    if (interviewTypeParam !== '') {
      interviewType = interviewTypeParam
      globalInterviewType = interviewType
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const interviewTypeQueryParam = urlParams.get('interview_type');
      if (interviewTypeQueryParam) {
        interviewType = interviewTypeQueryParam;
        globalInterviewType = interviewType
      }
    }

    if (session !== null) {
      interviewTypeParam = null
      sessionId = session.unique_session_id
    }

    const registerLivekitCall = () => {
      return axios.post(`${proxyEndpoint}/register_livekit_call`, {
        client_type: 'desktop',
        interview_type: interviewTypeParam,
        session_id: sessionId
      })
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          console.error('Error registering livekit call:', error);
        });
    }

    registerLivekitCall()
      .then((res) => {
        setLivekitToken(res.token)
        setVisualState('connected')
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handleStopInterview = () => {
    console.log('handleStopInterview')
    // get scorecard
    if (stopInvoked) {
      return
    }
    stopInvoked = true

    console.log('making request')
    axios.post(`${proxyEndpoint}/structured_outputs`, { callId: callId })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching structured outputs:', error);
      });

    axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching scorecard:', error);
      });

    axios.post(`${proxyEndpoint}/summary`, { callId: callId })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching summary:', error);
      });

    axios.post(`${proxyEndpoint}/recording`, { callId: callId })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching recording:', error);
      });

    setTimeout(() => {
      if (socket != null) {
        socket.close();
        socket = null;
      }
      // setInterview(emptyInterviewObject)                  
    }, 10000)
  }

  const handleStopRetellInterview = (userClick) => {
    // get scorecard
    if (stopInvoked) {
      return
    }
    stopInvoked = true
    if (userClick) {
      retellWebClient.stopCall()
      if (socket != null) {
        socket.close();
        socket = null;
      }
      setShowInterviewEndedMidway(true)
    } else {
      setShowGracefulExitMsg(true)
      setTimeout(() => {
        retellWebClient.stopCall()
        if (socket != null) {
          socket.close();
          socket = null;
        }
      }, 10000)
    }

    if (session && session.videoRecorder === 'builtin') {
      const recordingClient = client.getRecordingClient()
      recordingClient.stopCloudRecording()
        .then(() => {
          console.log('stopped')
          stream.stopVideo()
        })
        .catch((err) => {
          console.error(err)
        })
    }

    if (session && session.videoRecoder === 'loom') {
      console.log('stop loom recording')
    }

    axios.post(`${proxyEndpoint}/structured_outputs`, { callId: callId })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching structured outputs:', error);
      });

    axios.post(`${proxyEndpoint}/scorecard`, { callId: callId })
      .then((response) => {
        console.log('scorecard', response.data)
        if (response.data.type === 'multiple') {
          setScorecards(response.data.results)
        } else {
          setScorecard(response.data.results)
        }
      })
      .catch((error) => {
        console.error('Error fetching scorecard:', error);
      });

    axios.post(`${proxyEndpoint}/recording`, { callId: callId })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching recording:', error);
      });

    axios.post(`${proxyEndpoint}/summary`, { callId: callId })
      .then((response) => {
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching summary:', error);
      });
  }

  const handleSubmitDiagramFeedback = () => {
    console.log('handleSubmitDiagramFeedback')
    setLoadingDiagramFeedback(true);
    const buffering_reses = [
      'Hang on, taking a look at the whiteboard',
      'One sec, taking a look',
      'Looking, give me one second.',
      "Bear with me, I'm perusing the whiteboard details.",
      "Just a tick, I'm eyeing the whiteboard content.",
      'Allow me a brief pause to scan the whiteboard.',
      'I need a short moment to digest the whiteboard info.',
      'Let me take a swift glance at the whiteboard.',
      "I'm diving into the whiteboard, hold on.",
      'A quick study of the whiteboard is underway.',
      "I'm zooming in on the whiteboard, stand by.",
      'Peering into the whiteboard, just a moment.',
      "I'm deciphering the whiteboard scribbles, hang tight."
    ];
    const buffering_res = buffering_reses[Math.floor(Math.random() * buffering_reses.length)];

    if (!excalidrawAPI) {
      return
    }
    const elements = excalidrawAPI.getSceneElements();
    if (!elements || !elements.length) {
      return
    }

    const appState = excalidrawAPI.getAppState();

    exportToBlob({
      elements,
      appState: {
        ...appState,
        exportWithDarkMode: false,
      },
      files: excalidrawAPI.getFiles(),
      maxWidthOrHeight: 1000
    })
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          fetch(`${proxyEndpoint}/get_diagram_feedback`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ image: base64data, callId: callId })
          })
            .then((response) => {
              setLoadingDiagramFeedback(false);
              response.json()
                .then((jsonRes) => {
                  setFeedback(jsonRes.feedback)
                })
                .catch((err) => { console.error(err) })
            })
            .catch((err) => { console.error(err) })
        }
      })
  }

  const renderContext = () => {
    return interview.context.map((message, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
        <Avatar sx={{ bgcolor: 'white', color: 'black', width: 25, height: 25, border: '0.5px solid #4451f6', marginRight: 3 }}>S</Avatar>
        <Typography variant="body2" sx={{ fontFamily: 'PPNeueMontreal', color: 'black', }}>{message}</Typography>
      </div>
    ))
  }

  const renderFeedback = () => {
    if (feedback !== '') {
      return (
        <div key={0} style={{ display: 'flex', alignItems: 'center', paddingRight: 15, marginBottom: 25 }}>
          <Typography variant="body2" sx={{ fontFamily: 'PPNeueMontreal', color: 'white', fontWeight: 700 }}>
            {feedback}
          </Typography>
        </div>
      )
    } else {
      return (
        <></>
      )
    }
  }

  function secondsToHumanReadableTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

    return formattedTime;
  }

  const handleToggleMute = () => {
    console.log('handleToggleMute')
    socket.emit('mute', callId);
  }

  const enableMic = () => {
    setWaitingEnable(true);
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        setWaitingEnable(false);
        setMicEnabled(true);
      })
      .catch(function (err) {
        setWaitingEnable(false);
        console.log('Microphone permission denied');
      });
  }

  const handleSubmitText = () => {
    console.log('handleSubmitText', submission)
    setFeedbackPending(true)
    fetch(`${retellApiEndpoint}/text_input_submission`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        call_id: callId,
        submission: submission,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setFeedbackPending(false)
        setFeedback(data.feedback);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const getDisplayValue = (component) => {
    if (component === 'feedback') {
      if (current_question && current_question.type === 'text_input') {
        return true
      } else {
        return false
      }
    }
  }

  const renderEnableMicBtn = () => {
    if (session && (!session.videoRecorder || session.videoRecorder === 'builtin')) {
      return (
        <button className="btn" autoFocus disabled={micEnabled} onClick={() => { enableMic() }}>
          {micEnabled ? 'Microphone and Camera Enabled' : 'Enable Microphone and Camera'}
        </button>
      )
    } else {
      return (
        <button id={BUTTON_ID} className="btn" autoFocus disabled={micEnabled}>
          {micEnabled ? 'Microphone and Camera Enabled' : 'Enable Microphone and Camera'}
        </button>
      )
    }
  }

  const renderLetsGoBtn = () => {
    return (
      <Button
        className="btn-main"
        sx={{
          height: '40px',
          fontSize: '14px',
          padding: '12px',
          width: 'fit-content'
        }}
        onClick={() => {
          if (onboardAction === "Let's go") {
            setShowWebcamPreview(true); // Show webcam preview instead of starting interview directly
          } else {
            advanceOnboardStep();
          }
        }}
      >
        {onboardAction}
        <ArrowForwardIcon style={{ marginLeft: '8px', fontSize: 17 }} />
      </Button>
    );
  }

  const advanceOnboardStep = () => {
    console.log('advanceOnboardStep', onboardStep, onboardCopy[onboardStep])
    var step = onboardCopy[onboardStep];
    if (step && step.flag) {
      if (step.flag == 'mic') enableMic();
    }

    if (step.actionFn !== undefined) {
      console.log('onboardActionFn', step.actionFn)
      step.actionFn()
        .then(() => {
          setIntroTitle(`Meet Steve: Practice Session`)
          setUseIntroTitle(true)
          setInterviewStarted(true)
          setTimer(30)
        })
    }

    var newStep = onboardStep + 1
    var newProgress = onboardProgress + 25
    var practiceChatEnabled = false
    if (session.practiceChatEnabled) {
      practiceChatEnabled = session.practiceChatEnabled
    }

    if (onboardStep < onboardCopy.length) {
      if (onboardCopy.length - 1 >= newStep) {
        // check if practice chat enabled
        console.log('practice chat enabled', practiceChatEnabled, onboardCopy[onboardStep + 1])
        if (onboardCopy[newStep].practiceChat && !practiceChatEnabled) {
          // skip practice chat
          newStep = onboardStep + 3
          newProgress = onboardProgress + 50
        }

        if (onboardCopy[newStep].introVideo && !session.intro_video) {
          newStep = newStep + 1
          newProgress = Math.min(newProgress + 25, 100)
        }
      }

      setOnboardStep(newStep)

      step = onboardCopy[newStep];

      const progress = newProgress;
      setOnboardProgress(progress)
      setOnboardIcon(step.icon)
      setOnboardPrompt(step.prompt)
      setOnboardNote(step.note)
      setOnboardHeading(step.heading)
      setOnboardAction(step.action)
    } else {
      console.log('end onboarding')
      handleStartInterview(globalInterviewType)
    }
  }

  const handleStartOnboardRetellDemo = () => {
    console.log('handling onboard demo')
    setWaitingDemo(true);

    // add time limit to stop onboard demo convo
    const onboardCallTimeout = 30000;
    setTimeout(() => {
      setWaitingDemo(false);
      handleStopOnboardRetellDemo();
    }, onboardCallTimeout)

    // TODO: retellWebClient.startCall
    // https://docs.retellai.com/make-calls/web-call

  }

  const handleStopOnboardRetellDemo = () => {
    // TODO: sanity check stop call here as timeout stops
    retellWebClient.stopConversation()
  }

  var interviewType = 'system_design_event_ticketing'
  const urlParams = new URLSearchParams(window.location.search);
  const interviewTypeQueryParam = urlParams.get('interview_type');
  var interviewTypeModalText = ''
  if (interviewTypeQueryParam) {
    globalInterviewType = interviewTypeQueryParam;
  }

  // if(!waitlistOpened && timerElapsed >= 2 * 60 ) {    
  //   setWaitlistOpened(true)
  //   setWaitlistIsOpen(true)
  //   handleToggleMute();
  // }

  let current_question = undefined
  if (interview.metadata.interview_config.questions && interview.metadata.interview_config.questions.length > 0) {
    current_question = interview.metadata.interview_config.questions[interview.parrot_text_index]
  }

  const renderInterviewScreen = () => {
    return (
      <LiveKitRoom
        video={true}
        audio={true}
        token={livekitToken}
        serverUrl={process.env.REACT_APP_LIVEKIT_URL}
        // Use the default LiveKit theme for nice styles.
        data-lk-theme="default"
        style={{ height: '100vh' }}
        onDisconnected={() => {
          console.log('Disconnected from LiveKit')
          let queryParams = {
            direct: shouldRedirect ? 'true' : 'false',
          }
          let params = "?" + Object.entries(queryParams).map(([key, value]) => value ? `${key}=${value}` : '').join('&')
          if (posthog && window.location.href.startsWith('https://sessions.wayfaster.com')) {
            const user_id = getLocalStorageUserId();
      
            posthog.capture('sessions__interview_completed_complete', {
              'user_id': user_id,
              'current_url': window.location.href,
            })
            console.log('posthog event captured -- interview_completed', user_id)        
          }    
      
          navigate(`/complete${params}`)
        }}
      >
        {isMobile ?
          <div style={{ 
            height: '100vh', 
            display: 'flex', 
            flexDirection: 'column', 
            backgroundColor: 'black',
            overflow: 'hidden', // Prevent scrolling
            width: '100%',      // Ensure full width
            position: 'fixed',  // Fix position to prevent scrolling
            top: 0,
            left: 0
          }}>
            {/* Header */}
            <div style={{ 
              backgroundColor: 'black',
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <div style={{
                display: 'flex', 
                flexDirection: 'column',
                gap: '2px'
              }}>
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    fontFamily: 'Inter',
                    fontWeight: 500
                  }}
                >
                  {session !== null &&
                    <>
                      {useIntroTitle ?
                        <>
                          {introTitle}
                        </>
                        :
                        <>
                          {session.title}
                        </>
                      }
                    </>
                  }
                </Typography>
                <div className="font-inter" style={{ 
                  display: 'inline-flex', // Keep inline-flex
                  justifyContent: 'left',
                  alignItems: 'center',
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: 'Inter',
                  color: 'var(--color-text-on-dark)',
                  backgroundColor: 'var(--color-video-bg)',
                  padding: '4px 8px',
                  borderRadius: 10,
                  width: 'fit-content' // Add this to make the container fit its content
                }}>
                  <AccessTimeIcon sx={{ fontSize: 14 }} />
                  <span style={{ marginLeft: 5 }}>
                    {secondsToHumanReadableTime(timer)}
                  </span>
                </div>
              </div>
              
              <IconButton 
                onClick={() => { setShowStopInterviewConfirmation(true) }}
                style={{
                  background: '#ff0000',
                  color: 'white',
                  borderRadius: 10,
                  padding: '8px 12px 8px 8px',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 500,
                  marginLeft: 'auto'
                }}
                disabled={!interviewStarted}
              >
                <StopIcon style={{ marginRight: '4px', padding: '0px' }}/>
                Stop
              </IconButton>
            </div>

            {/* Video Feeds */}
            <div style={{ 
              flex: 1, 
              display: 'flex', 
              flexDirection: 'column',
              overflow: 'hidden', // Prevent scrolling
              paddingBottom: '80px' // Add space for transcript
            }}>
              {/* User Video */}
              <div style={{
                height: 'calc(40vh - 60px)', // Reduced from 50vh to 40vh
                margin: '0px 16px 16px 16px',
                position: 'relative',
                backgroundColor: 'var(--color-video-bg)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '16px',
                overflow: 'hidden' // Ensure content doesn't overflow
              }}>
                <VideoFeed videoStream={videoStream} isMobile={true}/>
                <Typography 
                  sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    color: 'white',
                    backgroundColor: 'var(--color-video-bg)',
                    fontSize: 16,
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    zIndex: 1001, // Add zIndex to ensure name appears above video
                    padding: '4px 8px',
                    borderRadius: '8px'
                  }}
                >
                  {candidateName}
                </Typography>
              </div>

              {/* AI Avatar */}
              <LivekitAvatar interviewStarted={interviewStarted} closeOnboarding={closeOnboarding} visualState={visualState} setVisualState={setVisualState} session={session} aiName={AIName} isMobile={isMobile} />
            </div>

            {/* Transcript Panel with Toggle Button */}
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'var(--color-video-bg)',
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                padding: '16px',
                height: transcriptExpanded ? '66vh' : '60px',
                overflow: 'hidden',
                boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
                transition: 'height 0.3s ease',
                zIndex: 1000
              }}
            >
              {/* Toggle button with flat line indicator */}
              <div 
                onClick={toggleTranscript}
                style={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
              >
                {/* Flat line indicator */}
                <div style={{
                  width: '48px',
                  height: '4px',
                  backgroundColor: 'white',
                  borderRadius: '2px',
                  marginBottom: '4px'
                }} />
                
                <Typography 
                  variant="h6" 
                  sx={{ 
                    mb: 2, 
                    color: 'white', 
                    fontFamily: 'Inter', 
                    fontWeight: 500, 
                    fontSize: 16, 
                    textAlign: 'center'
                  }}
                >
                  {transcriptExpanded ? 'Tap to hide transcript' : 'Tap to open transcript'}
                </Typography>
              </div>
              
              <div 
                style={{ 
                  display: transcriptExpanded ? 'block' : 'none',
                  overflowY: 'auto',
                  height: 'calc(100% - 60px)',
                  backgroundColor: 'var(--color-video-bg)', // Add background color to message view
                  borderRadius: '8px',
                  padding: '8px'
                }}
              >
                {transcript.map((message, index) => (
                  <TranscriptMessage 
                    isMobile={true}
                    key={index} 
                    message={message.content} 
                    role={message.role} 
                    speaker={message.role === 'agent' ? AIName : 'Candidate'} 
                    showSpeaker={true}
                  />
                ))}
              </div>
            </div>
          </div>
        :  
        <div maxWidth={false} disableGutters={true} className="candidate-interview-container">
            {/* Interview Status Bar */}
            <div className="d-flex flex-row" style={{ alignItems: 'center', padding: '16px 40px', backgroundColor: "var(--color-video-bg)" }}>
              <div className="font-inter d-flex" style={{ alignItems: 'center', marginRight: 30, fontSize: 16, color: 'var(--color-text-white)' }}>
                {/* Interview Title */}
                {!isMobile &&
                  <div className="font-inter">
                    {session !== null ? session.jobTitle : ''} 
                  </div>
                }
              </div>

              {(!isMobile && interview.metadata.interview_config.title.startsWith('System Design')) &&
                <IconButton onClick={handleSubmitDiagramFeedback} disabled={loadingDiagramFeedback || !interviewStarted} style={{
                  background: 'white',
                  color: 'black',
                  borderRadius: 10,
                  fontFamily: 'PPNeueMontreal',
                  paddingLeft: 12,
                  paddingRight: 12,
                  fontSize: 16,
                  marginLeft: 'auto', // Added to move the button to the right
                }}>
                  {loadingDiagramFeedback ? 'Syncing...' : <><SyncIcon />
                    Sync</>}
                </IconButton>
              }
              {((isMobile && firstMobileScreenDone) || !isMobile) &&
                <div className="d-flex flex-row timer-container-dark" style={{ marginLeft: 'auto' }}>
                  <AccessTimeIcon style={{ fontSize: 16, margin: 'auto', marginRight: 4 }}/>
                  <p className="font-inter" style={{ margin: 'auto', fontSize: '14px', fontWeight: 500}}>
                    {secondsToHumanReadableTime(timer)}
                  </p>
                </div>
              }
              {(interviewStarted && !isMobile) &&
                <>
                  <IconButton className="font-inter" onClick={() => { setShowStopInterviewConfirmation(true) }} style={{
                    background: 'var(--color-red)',
                    color: 'white',
                    borderRadius: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                    fontSize: 16,
                    marginLeft: 15, // Added to move the button to the right
                  }} disabled={!interviewStarted}>
                    <StopIcon style={{ marginRight: '4px' }}/>
                    Stop Interview
                  </IconButton>
                </>
              }
            </div>
            <div className="d-flex flex-row" style={{ flex: 3, width: '100%', height: 'calc(100vh - 72px)', position: 'relative' }}>
              {/* Main Content */}
              <div className="d-flex flex-col" style={{ flex: 3, width: '100%', height: '100%', position: 'relative' }}>
              {/* Webcam View */}
              {/* <div className="d-flex" style={{
                width: 'auto',
                height: 'auto',
                aspectRatio: '16 / 9',
                textAlign: 'center',
                borderRadius: '16px',
                margin: '16px',
                flex: 1,
                overflow: 'hidden',
                position: 'relative',
                zIndex: 1000
              }}>
                <VideoFeed videoStream={videoStream} speaker={candidateName}/>
                <LivekitAvatar interviewStarted={interviewStarted} closeOnboarding={closeOnboarding} visualState={visualState} setVisualState={setVisualState} session={session} aiName={AIName} isMobile={isMobile} />
              </div> */}
              <Container maxWidth={false} disableGutters={true} style={{ height: '85%', display: 'flex' }}> 
                <div className="d-flex flex-row" style={{ gap: 24, flex: 1, margin: 'auto', padding: '3vw' }}> 
                  <VideoFeed videoStream={videoStream} speaker={candidateName}/>
                  <LivekitAvatar interviewStarted={interviewStarted} closeOnboarding={closeOnboarding} visualState={visualState} setVisualState={setVisualState} session={session} aiName={AIName} isMobile={isMobile} />
                </div>
              </Container>
              
              <Container maxWidth={false} disableGutters={true} style={{ height: '20%', display: 'flex' }}> 
                <ControlBar 
                    variation="minimal"
                    controls={{
                      leave: false,
                      screenShare: false
                    }}
                    className="mx-auto"
                    style={{ borderTop: 'none' }}
                  />
              </Container>

              <div dangerouslySetInnerHTML={{ __html: videoHTML }}></div>
              </div>
              {/* Right Sidebar */}
              {interviewStarted && closeOnboarding && 
              <div style={{ flex: 1, background: 'var(--color-video-bg)', margin: '24px', borderRadius: '16px' }}>
                <div className="d-flex flex-col" style={{ padding: '16px' }}> 
                  {/* Main Background / Interview Tabs */}
                  <div style={{ backgroundColor: 'var(--color-dark-gray-bg)', padding: '8px 8px 0px 8px', borderRadius: '8px' }}>
                    <Tabs value={currentRightSidebarTab} onChange={handleRightSidebarTabChange}
                      sx={{
                        '& .MuiTabs-scroller': {
                          borderBottom: '1px solid var(--color-divider)',
                        },
                        '& .MuiTabs-indicator': {
                          backgroundColor: 'var(--color-main)',
                        },
                        '& .MuiTab-root': {
                          color: "var(--color-text-inactive)", 
                          fontSize: '14px',
                          borderRadius: '8px',
                          padding: '8px 16px',
                          marginRight: '4px',
                          fontFamily: 'Inter',
                          textTransform: 'none',
                          minHeight: '40px',
                          flex: 1
                        },
                        '& .MuiTab-root.Mui-selected': {
                          color: 'var(--color-text-white)',
                          backgroundColor: overview && overview.length > 0 ? 'var(--color-video-bg)' : 'none',
                          fontWeight: 600
                        },
                      }}>
                      <Tab label="Live Transcript" onClick={() => setCurrentRightSidebarTab(0)} disableRipple />  
                      {overview && overview.length > 0 && <Tab label="Context" onClick={() => setCurrentRightSidebarTab(1)} disableRipple />}
                    </Tabs>
                  </div>
                  <div className="d-flex flex-col" style={{ padding: '16px' }}> 
                    <div 
                      className="d-flex flex-col" 
                      style={{ 
                        overflowY: 'auto', 
                        height: 'calc(100vh - 265px)', 
                        paddingRight: '16px',
                        scrollBehavior: 'smooth'
                      }}
                      ref={el => {
                        if (el && currentRightSidebarTab === 0) {
                          el.scrollTop = el.scrollHeight;
                        }
                      }}
                    >
                      <LiveTranscript aiName={AIName} candidateName={candidateName} isMobile={isMobile} transcriptExpanded={transcriptExpanded} transcript={transcript} setTranscript={setTranscript} currentRightSidebarTab={currentRightSidebarTab} />
                      {currentRightSidebarTab === 1 &&
                        <>
                          {overview.length > 0 ? 
                            <ReactMarkdown components={{
                              h1: ({node, ...props}) => <h1 style={{ fontSize: '20px', fontWeight: 600, marginBottom: '12px', color: 'white' }} {...props} />,
                              h2: ({node, ...props}) => <h2 style={{ fontSize: '18px', fontWeight: 600, marginBottom: '10px', color: 'white' }} {...props} />,
                              h3: ({node, ...props}) => <h3 className="job-text-description-header font-inter" style={{ color: 'var(--color-text-white)' }} {...props} />,
                              h4: ({node, ...props}) => <h4 className="job-text-description-header font-inter" style={{ color: 'var(--color-text-white)' }}   {...props} />,
                              p: ({node, ...props}) => <p className="job-text-description font-inter" style={{ color: 'var(--color-text-white)', marginBottom: '24px' }} {...props} />,
                              ul: ({node, ...props}) => <ul className="job-text-description font-inter" style={{ color: 'var(--color-text-white)', marginBottom: '24px' }} {...props} />,
                            }}>
                              {overview}
                            </ReactMarkdown>
                            : 
                          <div>
                            <p className="font-inter" style={{ marginLeft: '8px', color: 'gray' }}>No context available.</p>
                          </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                  <span className="font-circular" style={{ fontSize: 12, color: '#656565', position: 'fixed', bottom: 36, right: 36 }}>&copy; Wayfaster 2025</span>
                </div>
              </div>}
            </div>
        </div>}
        <RoomAudioRenderer />
      </LiveKitRoom>
    )
  }

  const renderPracticeChatInterviewScreen = () => {
    return (
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          backgroundColor: 'white',
          color: 'black',
          m: 0,
          margin: 'auto',
          textAlign: 'center',
          width: isMobile ? '90%' : '100%',
          display: 'flex',
          height: '100%',
          maxHeight: '100%',
          position: 'relative'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <Box sx={{ height: '80%', mt: 3, width: mediaQuery ? '90%' : '100%', px: mediaQuery ? 2 : 0 }}>
            {(!current_question && (current_question && current_question.type !== 'text_input') && !interview.metadata.interview_config.title.startsWith('System Design')) &&
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: mediaQuery ? 4 : 9 }}>
                  <Typography sx={{ fontFamily: 'PPNeueMontreal', fontSize: 18 }}>Instructions</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  {renderContext()}
                </Box>
              </>
            }
            {(current_question && current_question.type === 'text_input') &&
              <Box sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src={current_question.metadata.media.url}
                  sx={{ width: '100%', maxWidth: '1100px' }}
                />
                <textarea
                  placeholder="Enter your submission attempt here"
                  value={submission}
                  onChange={(e) => { setSubmission(e.target.value) }}
                  style={{
                    width: '100%',
                    maxWidth: '800px',
                    fontFamily: 'PPNeueMontreal',
                    fontSize: 14,
                    height: 200,
                    padding: 10,
                    borderRadius: 10,
                    marginTop: '1rem',
                    marginBottom: '1rem'
                  }}
                />
                <Button
                  variant="contained"
                  onClick={handleSubmitText}
                  disabled={feedbackPending}
                  sx={{ mt: 2, mb: 4 }}
                >
                  {feedbackPending ? <><CircularProgress size={20} /> &nbsp;&nbsp; Getting Feedback</> : "Submit Attempt"}
                </Button>
              </Box>
            }
            {interview.metadata.interview_config.title.startsWith('System Design') &&
              <>
                <Typography sx={{ mb: 2 }}>Draw in the Whiteboard and hit "Sync" in the top right to get any thoughts across.</Typography>
                <Box sx={{ height: '80vh' }}>
                  <Excalidraw
                    theme="light"
                    excalidrawAPI={(api) => setExcalidrawAPI(api)}
                  />
                </Box>
              </>
            }

            <div className="font-inter" style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
              <div style={{
                fontSize: isMobile ? '20px' : '20px',
                fontWeight: 600,
                marginTop: isMobile ? '2rem' : '25vh'
              }}>
              <div className={`d-flex onboard-icon-container mic`}>
                <img src={onboardMic} alt="onboard icon"></img>
              </div>
              {visualState === '' &&
                <p className="font-inter" style={{ fontSize: '20px' }}>{interviewerName} is connecting...</p>
              }
              {visualState === 'listening' &&
                <>
                  <p className="font-inter" style={{ fontSize: '20px' }}>{interviewerName} is <b>listening</b></p>
                  <img
                    src={Listening}
                    alt="listening"
                    style={{
                      marginTop: '1rem',
                      width: isMobile ? '80%' : 'auto',
                      maxWidth: '200px'
                    }}
                  />
                </>
              }
              {visualState === 'speaking' &&
                <>
                  <p className="font-inter" style={{ fontSize: '20px' }}>{interviewerName} is <b>speaking</b></p>
                  <img
                    src={Listening}
                    alt="speaking"
                    style={{
                      marginTop: '1rem',
                      width: isMobile ? '80%' : 'auto',
                      maxWidth: '200px'
                    }}
                  />
                </>
              }
              {visualState === 'connected' &&
                <p className="font-inter" style={{ fontSize: '20px' }}>{interviewerName} is <b>connected</b></p>
              }
              {visualState === 'disconnected' &&
                <p className="font-inter" style={{ fontSize: '20px' }}>{interviewerName} has <b>left the call</b></p>
              }
              </div>
            </div>
            <Box dangerouslySetInnerHTML={{ __html: videoHTML }}></Box>
          </Box>
        </Box>
      </Container>
    )
  }
  
  const renderIntroVideoScreen = () => {
    return (
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          backgroundColor: 'white',
          color: 'black',
          m: 0,
          margin: 'auto',
          textAlign: 'center',
          width: isMobile ? '90%' : '100%',
          display: 'flex',
          height: '100%',
          maxHeight: '100%',
          position: 'relative'
        }}
      >
        
        <Container sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <video src={session.intro_video} 
            style={{
              width: '100%',
              maxHeight: 'calc(100vh - 100px)',
              objectFit: 'cover',
              borderRadius: '8px'
            }}
            autoPlay playsInline onEnded={() => {
            advanceOnboardStep()
          }}/>
        </Container>
      </Container>
    )
  }

  const [showWebcamPreview, setShowWebcamPreview] = useState(false); // Changed initial state to false
  const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
  const [selectedAudioDevice, setSelectedAudioDevice] = useState('');

  // Add this function to handle device selection
  const handleDevicesSelected = (videoDeviceId, audioDeviceId) => {
    setSelectedVideoDevice(videoDeviceId);
    setSelectedAudioDevice(audioDeviceId);
    setShowWebcamPreview(false);
    setCloseOnboarding(true); // Close onboarding after device selection
    handleStartInterview(globalInterviewType); // Start interview after device selection
  };

  // Add these state variables near the top of the App component
  const [videoStream, setVideoStream] = useState(null);

  // Add this useEffect to handle video stream setup
  useEffect(() => {
    retellWebClient.on("call_started", () => {
      console.log("call started");
      setVisualState('connected')
    });

    retellWebClient.on("call_ended", () => {
      console.log("call ended");
      setVisualState('disconnected')
    });

    // When agent starts talking for the utterance
    // useful for animation
    retellWebClient.on("agent_start_talking", () => {
      console.log("agent_start_talking");
      setVisualState('speaking')
    });

    // When agent is done talking for the utterance
    // useful for animation
    retellWebClient.on("agent_stop_talking", () => {
      console.log("agent_stop_talking");
      setVisualState('listening')
    });

    retellWebClient.on("update", (update) => {
      console.log('update', update)
      if (onboardCopy[onboardStep].interviewScreen && update.transcript && update.transcript[update.transcript.length - 1]?.content.includes("back to the onboarding")) {
        setTimeout(() => {
          setUseIntroTitle(false)
          setInterviewStarted(false)
          retellWebClient.stopCall()
          setTimer(session.length * 60)
          advanceOnboardStep()
          setVisualState('')
          introDone = true
        }, 7000)
      }
    });    

    if (selectedVideoDevice && selectedAudioDevice && interviewStarted) {
      navigator.mediaDevices.getUserMedia({
        video: { deviceId: selectedVideoDevice },
        audio: { deviceId: selectedAudioDevice }
      })
        .then(stream => {
          setVideoStream(stream);
        })
        .catch(err => {
          console.error("Error accessing media devices:", err);
        });

      // Cleanup function
      return () => {
        if (videoStream) {
          videoStream.getTracks().forEach(track => track.stop());
        }
      };
    }
  }, [selectedVideoDevice, selectedAudioDevice, interviewStarted]);

  return (
    <>
      {/* Webcam preview modal - Always require webcam */}
      <WebcamPreviewModal
        isMobile={isMobile}
        isWebcamRequired={true} 
        open={showWebcamPreview}
        timer={timer}
        jobTitle={session && session.jobTitle ? session.jobTitle : ''}
        onClose={() => setShowWebcamPreview(false)}
        onDevicesSelected={handleDevicesSelected}
      />

      {/* Rest of the components */}
      {renderInterviewScreen()}

      {/* Onboarding dialog */}
      {!closeOnboarding && !showWebcamPreview && (
        <Dialog
          open={true}
          maxWidth={false}
          fullWidth
          fullScreen
        >
          {/* <OnboardLinearProgress
            variant="determinate"
            value={onboardProgress}
            style={{ margin: '8px' }}
          /> */}
          <div style={{
            padding: 16,
            margin: '16px 24px',
            border: '1px solid var(--color-border-gray)',
            borderRadius: 16,
          }} className={`d-flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
              <div className="font-inter d-flex my-auto" style={{ marginRight: 'auto', marginLeft: isMobile ? 'auto' : '', fontSize: 18, color: 'var(--color-text-on-light)'}}>
                <a href="https://wayfaster.com" rel="noopener noreferrer" target="_blank"><img src={WayfasterLogo} alt="Wayfaster" style={{ height: '28px' }} /></a>
              </div>
              <div className="d-flex" style={{ marginLeft: 'auto', marginRight: isMobile ? 'auto' : '' }}>
                <p className="font-inter" style={{ margin: 'auto', fontSize: 14, color: 'var(--color-text-on-light)', fontWeight: 500}}>
                  { useIntroTitle ? introTitle : session && session.jobTitle ? session.jobTitle : ''}
                </p>
                <p className="font-inter" style={{ margin: 'auto 16px', fontSize: 18, color: '#E4E4E7', fontWeight: 500}}>
                  |
                </p>
                <div className="d-flex flex-row timer-container" style={{ marginLeft: 'auto' }}>
                  <AccessTimeIcon style={{ fontSize: 16, margin: 'auto', marginRight: 4 }}/>
                  <p className="font-inter" style={{ margin: 'auto', fontSize: '14px', fontWeight: 500}}>
                    {secondsToHumanReadableTime(timer)}
                  </p>
                </div>

                {/* Add for skip chat */}
                
                {onboardCopy[onboardStep].interviewScreen && interviewStarted &&
                  <>
                    {!introDone &&
                      <Button
                        className="btn-main"
                        sx={{
                          marginLeft: '16px',
                          height: '36px',
                          fontSize: '14px',
                          padding: '12px',
                          width: 'fit-content'
                        }}
                        onClick={() => {
                          setUseIntroTitle(false)
                          setInterviewStarted(false)
                          retellWebClient.stopCall()
                          setTimer(session.length)
                          advanceOnboardStep()
                          setVisualState('')
                          introDone = true
                        }}
                        disabled={!interviewStarted}
                      >
                        {isMobile ? "Skip" : "Skip chat & continue"}
                        <ArrowForwardIcon style={{ marginLeft: '8px', fontSize: 17 }} />
                      </Button>
                    }
                  </>
                }
                {onboardCopy[onboardStep].introVideo &&
                  <>
                    <Button
                      className="btn-main"
                      sx={{
                        marginLeft: '16px',
                        height: '36px',
                        fontSize: '14px',
                        padding: '12px',
                        width: 'fit-content'
                      }}
                      onClick={() => {
                        advanceOnboardStep()
                      }}
                    >
                      {isMobile ? "Skip" : "Skip intro video & continue"}
                      <ArrowForwardIcon style={{ marginLeft: '8px', fontSize: 17 }} />
                    </Button>
                  </>
                }
              </div>
          </div>
          <DialogContent style={{
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid var(--color-border-gray)',
            borderRadius: 16,
            padding: 16,
            margin: '16px 24px',
            height: mediaQuery ? '100vh' : '80vh',
            display: 'flex',
            flexDirection: 'column',
          }}>
            <DialogContentText component="div" style={{
              display: "flex",
              flexDirection: "column",
              height: '100%',
              width: '100%',
              color: 'inherit',
            }}>
              {sessionLoading ? (
                <div style={{ margin: 'auto', textAlign: 'center', fontSize: '18px', fontWeight: 600 }}>
                  Loading session <CircularProgress size={20} style={{ marginLeft: '10px' }} />
                </div>
              ) : (!session || Object.keys(session).length === 0) ? (
                <>
                  {sessionAttempted ? (
                    <div style={{ margin: 'auto', textAlign: 'center', fontSize: '18px', fontWeight: 600 }}>
                      You have already attempted this interview session. Please contact your recruiter for further assistance.
                    </div>
                  ) : (
                    <div style={{ margin: 'auto', textAlign: 'center', fontSize: '18px', fontWeight: 600 }}>
                      No session found. Please try again and ensure this is a valid session page.
                    </div>
                  )}
                </>
              ) : (
                <>
                  {onboardCopy[onboardStep].interviewScreen ?
                    renderPracticeChatInterviewScreen()
                    :
                    onboardCopy[onboardStep].introVideo ?
                      renderIntroVideoScreen()
                    :
                    <div style={{
                      textAlign: 'center',
                      margin: 'auto',
                      maxWidth: '500px',
                      width: 'auto',
                      padding: '16px',
                    }}>
                      {onboardIcon &&<div className={`d-flex onboard-icon-container ${onboardStep === 0 ? 'star' : onboardStep === 1 ? 'play' : 'mic'}`}>
                        <img src={onboardIcon} alt="onboard icon"></img>
                      </div>}
                      <div style={{ marginBottom: '12px' }}>{onboardHeading}</div>
                      <div style={{ marginBottom: '24px' }}>{onboardPrompt}</div>
                      {onboardNote &&
                        <div className="d-flex" style={{ marginBottom: '16px' }}>{onboardNote}</div>
                      }
                      <div>{renderLetsGoBtn()}</div>
                    </div>
                  }

                  {onboardStep === 2 && micEnabled &&
                    <CheckCircleIcon style={{ fontSize: '20px', color: '#1976d2', margin: '8px' }} />
                  }

                  {onboardStep === 3 && waitingDemo &&
                    <img
                      src={Soundbar}
                      alt="Sound visualization"
                      style={{
                        height: '24px',
                        marginTop: '24px',
                        marginRight: 'auto',
                      }}
                    />
                  }
                </>
              )}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}

      <Dialog open={showStopInterviewConfirmation}>
        <DialogTitle className="font-inter">
          Stop Interview Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="font-inter">
            Are you sure you want to end the interview?
            <br /><br />
            This means we will only evaluate you based on your partial input thus far and not on the full interview. This may impact your interview performance and evaluation for the given role.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn-main outlined gray" onClick={() => { setShowStopInterviewConfirmation(false) }}>
            No, Resume.
          </button>
          <button className="btn-main danger" onClick={() => {
            setShowStopInterviewConfirmation(false)
            axios.post(proxyEndpoint + '/delete_livekit_call', {
              session_id: sessionId
            })
            .then(res => {
              console.log(res)
              window.location.reload()
            })
            .catch(err => {
              console.error(err)
            })
          }
          }>
            Yes, Stop.
          </button>
        </DialogActions>
      </Dialog>


      <Dialog open={showGracefulExitMsg}>
        <DialogTitle className="font-inter">
          Interview Complete
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="font-inter">
            The interview has ended. Thank you so much for taking the time to interview with us. We will be getting back to you shortly on next steps. You can now exit this window.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={showInterviewEndedMidway}>
        <DialogTitle className="font-inter">
          Interview Stopped before Completion
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="font-inter">
            The interview was ended before completion. We can only evaluate you based on your partial input thus far. This may impact your interview performance and evaluation for the given role. You may now exit this window.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const LiveTranscript = ({ aiName, candidateName, isMobile, transcriptExpanded, transcript, setTranscript, currentRightSidebarTab }) => {
  const tracks = useTracks([{source: Track.Source.Microphone, withPlaceholder: true}]);

  const agentTrack = tracks.find(t => t.source === Track.Source.Microphone && t.participant.identity.startsWith('agent'));
  const { segments: agentTranscriptions } = useTrackTranscription(agentTrack);

  const userTrack = tracks.find(t => t.source === Track.Source.Microphone && t.participant.identity === livekitUserIdentity);
  const { segments: userTranscription } = useTrackTranscription(userTrack);

  useEffect(() => {
    // Combine and sort transcriptions by receivedAt timestamp
    const combinedTranscript = []
    if (agentTranscriptions) {
      combinedTranscript.push(...agentTranscriptions.map(t => ({
        ...t,
        role: 'agent',
        lastReceivedTime: t.lastReceivedTime || 0
      })))
    }
    if (userTranscription) {
      combinedTranscript.push(...userTranscription.map(t => ({
        ...t,
        role: 'user', 
        lastReceivedTime: t.lastReceivedTime || 0
      })))
    }

    // Sort by receivedAt timestamp
    combinedTranscript.sort((a, b) => {
      const timeA = a.lastReceivedTime || 0;
      const timeB = b.lastReceivedTime || 0;
      return timeA - timeB;
    });

    // Only update if there are changes
    if (combinedTranscript.length !== transcript.length || 
      combinedTranscript.some((t, i) => t.text !== transcript[i]?.text)) {
      setTranscript(combinedTranscript);
    }
  }, [agentTranscriptions, userTranscription, transcript, setTranscript]);

  // Group consecutive messages from the same speaker
  const groupedTranscript = useMemo(() => {
    const grouped = [];
    let currentSpeakerRole = null;
    
    transcript.forEach((message, index) => {
      // Add showSpeaker flag only for first message of each speaker group
      const showSpeaker = message.role !== currentSpeakerRole;
      
      // Check if this is the last message from this speaker
      const nextMessage = transcript[index + 1];
      const isLastInGroup = !nextMessage || nextMessage.role !== message.role;
      
      grouped.push({
        ...message,
        showSpeaker,
        isLastInGroup,
        speaker: message.role === 'agent' ? aiName : candidateName
      });
      
      // Update current speaker for next iteration
      currentSpeakerRole = message.role;
    });
    
    return grouped;
  }, [transcript, aiName, candidateName]);

  return (
    <div
    style={isMobile ? { 
      display: transcriptExpanded ? 'block' : 'none',
      overflowY: 'auto',
      height: 'calc(100% - 60px)',
      backgroundColor: 'var(--color-video-bg)',
      borderRadius: '8px',
      padding: '8px'
    } : {
      display: currentRightSidebarTab === 0 ? 'block' : 'none',
    }}>
      {groupedTranscript.length > 0 ? groupedTranscript.map((message, index) => (
        <TranscriptMessage 
          key={index} 
          isMobile={isMobile} 
          message={message.text} 
          role={message.role} 
          speaker={message.speaker}
          showSpeaker={message.showSpeaker}
          isLastInGroup={message.isLastInGroup}
        />
      )) : 
      <div>
        <p className="font-inter" style={{ color: 'gray' }}>No transcript available.</p>
      </div>
      }  
    </div>
  )
}

const LivekitAvatar = ({ interviewStarted, closeOnboarding, visualState, setVisualState, session, aiName, isMobile }) => {  

  const { state } = useVoiceAssistant();
  useEffect(() => {
    setVisualState(state);
  }, [state]);
  
  return (
    interviewStarted && closeOnboarding && 
    <div className={isMobile ? '' : `d-flex flex-col ai-speaker-card ${visualState}`} style={isMobile ? {
      height: 'calc(40vh - 60px)', // Reduced from 50vh to 40vh
      margin: '0px 16px 0px 16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--color-video-bg)',
      borderRadius: '16px',
      overflow: 'hidden' // Ensure content doesn't overflow
    } : {}}>
      <div className="d-flex flex-col m-auto">
        <Avatar src={getRetellAgentPhoto(session.voice, session.language)} className="mx-auto" 
        sx={{ bgcolor: 'white', color: 'black', width: isMobile ? 80 : 64, height: isMobile ? 80 : 64, border: '1px solid #4451f6', marginBottom: '6px' }}></Avatar>
        <h3 className="mx-auto font-inter" style={{ fontSize: isMobile ? 16 : 14, fontFamily: 'Inter', fontWeight: 600, color: isMobile ? 'white' : '' }}>{aiName}</h3>
        
        <p className="font-inter" style={{ margin: '0 auto', color: 'var(--color-text-on-dark)', fontSize: isMobile ? '14px' : '12px', fontFamily: 'Inter', fontWeight: 400 }}>
          {visualState === '' &&
            <div style={{ color: interviewStarted ? 'var(--color-text-on-dark)' : '#F8F8F8' }}>
              Connecting...
            </div>
          }
          {visualState === 'listening' &&
            <div>
              <b>Listening</b>
            </div>
          }
          {visualState === 'speaking' &&
            <div>
              <b>Speaking</b>
            </div>
          }
          {visualState === 'connected' &&
            <div>
              <b>Connected</b>
            </div>
          }
          {visualState === 'disconnected' &&
            <div>
              has <b>left the call</b>
            </div>
          }
        </p>
      </div>
    </div>
  )
} 

export default App;
